import Dropdown from "../../Components/Fields/Dropdown";
import {
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  UpdateRecord,
  URLPRIFIX,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";
import FileInput from "../../Components/Fields/FileInput";
import Attachement from "../../Components/Fields/Attachement";

const TasksForm = () => {
  const { option, id } = useParams();
  const tablename = "task";
  const setrecord = "/subtask/addsubtasks";
  const getrecord = `/subtask/usersubtasks/${id}`;
  const updaterecord = "/subtask/updatesubtask/" + id;

  const store = useStore();
  const init = {
    project_id: "",
    form_id: "",
    contact_id: "",
    task_id: "",
    subtask_title: "",
    assigned_to: "",
    dueDate: "",
    priority: "",
    status: "",
    description: "",
    hold_note: "",
    complete_note: "",
    activeStatus: true,
    attachmenturl: [],
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      store.setprops("Holdpopup", { hidden: true });
      // store.setprops("holdReason", { hidden: true });
      // store.setprops("complitionNotes", { hidden: true });

      if (option === "view") {
        store.setprops("status", { hidden: false, readonly: true });
      } else {
        store.setprops("status", { hidden: false, readonly: false });
      }

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.project_id = res.record.parent[0].project_id;
          init.form_id = res.record.parent[0].form_id;
          init.task_id = res.record.parent[0].task_id;
          init.contact_id = res.record.parent[0].contact_id;
          init.subtask_title = res.record.parent[0].subtask_title;
          init.assigned_to = res.record.parent[0].assigned_to;
          init.dueDate = res.record.parent[0].dueDate;
          init.priority = res.record.parent[0].priority;
          init.hold_note = res.record.parent[0].hold_note || "";
          init.complete_note = res.record.parent[0].complete_note || "";
          init.status = res.record.parent[0].status;
          init.description = res.record.parent[0].description;
          init.editor_id = res.record.child[0].editor_id;
          // init.activeStatus = res.record.parent[0].activeStatus;
          init.attachmenturl = res.record.parent[0].attachmenturl || [];

          store.reset(init);
          init.hold_note === "" &&
            store.setprops("hold_note", { hidden: true, readonly: true });
          init.complete_note === "" &&
            store.setprops("complete_note", { hidden: true, readonly: true });
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("status", { hidden: true, readonly: true });
      store.setvalue("status", "Draft");
    }
    GetListDropdown({
      listUrl: "/project/getprojectlist",
      field: "projectName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/tasks/getprojectstasklist",
      field: "task_title",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/forms/getformslist",
      field: "name_of_Artist",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/contact/getcontacts",
      field: "clientName",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, []);

  function update() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }
  function inprogress() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.status = "inprogress";
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        alert("States Update to - " + res.Id.response.status);
        console.log("States Update to - ", res);
        store.setvalue("status", "inprogress");
        store.setvalue("hold_note", "");
        store.setprops("hold_note", { hidden: true, readonly: true });
        // store.navback();
        // store.reset(init);
      }
    });
  }
  function hold() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    data.status = "hold";
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.setvalue("status", "hold");
        alert("States Update to - " + res.Id.response.status);
        store.setprops("holdReason", { hidden: false, readonly: true });
        store.setprops("Holdpopup", { hidden: true });
      }
    });
  }
  function complete() {
    let data = store.getrecord();
    // data.modifiedBy = store.getuser().id;
    // data.status = "Completed";
    // data.id = Number(id);
    // if (typeof data.activeStatus === "string") {
    //   data.activeStatus = data.activeStatus == "true" ? true : false;
    // }
    console.log("Data", data);

    UpdateRecord("/subtask/updatestatus/" + data.task_id + "/" + id).then(
      (res) => {
        console.log("updaterecord", res);

        if (res.success) {
          // alert("States Update to - " + res.Id.response.status);
          store.setvalue("status", "completed");
          store.setprops("complitionNotes", {
            hidden: false,
            readonly: true,
          });
          store.setprops("completePopup", { hidden: true });
        }
      }
    );
  }

  function Submit() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    saveRecord(setrecord, data).then((res) => {
      console.log("Resonce of saveRecord - ", res);
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }

  function TaskNameChange(id) {
    GetRecords("/tasks/gettask/" + id).then((res) => {
      if (res.success) {
        console.log("ChangeprojectName responce", id, res, res?.contact_id);
        res = res?.record?.parent[0] || {};
        console.log("ChangeprojectName", res, res?.contact_id);
        store.setvalue("contact_id", res?.contact_id || "");
        store.setvalue("form_id", res?.form_id || "");
        store.setvalue("project_id", res?.project_id || "");
      }
    });
  }

  function ClientNameChange(client_id) {
    GetRecords("/contact/contacts/" + client_id).then((res) => {
      // res = res?.record && res?.record[0].form;
      if (res.success) {
        res = res.record.child.map((item) => {
          return { value: item.id, label: item.name_of_Artist };
        });
        store.setprops("name_of_Artist_list", res);
      }
      console.log("ClientNameChange", res);
      // store.setvalue("contact_id", res.contact_id);
    });
  }

  function FormNameChange(form_id) {
    GetRecords("/forms/getforms/" + form_id).then((res) => {
      res = res?.record && res?.record[0].form;
      console.log("FormNameChange", res);
      store.setvalue("contact_id", res?.contact_id);
    });
  }
  function projectNameChange(project_id) {
    GetRecords("/project/getproject/" + project_id).then((res) => {
      res = res?.record && res?.record.parent;
      console.log("projectNameChange", res);
      store.setvalue("contact_id", res?.contact_id);
      store.setvalue("form_id", res?.form_id||"");
      FormNameChange(res?.form_id)
      ClientNameChange(res?.contact_id)
    });
  }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Start",
          onclick: inprogress,
          type: "primary",
          visible:
            option === "view" &&
            (store.getvalue("status") === "Draft" ||
              store.getvalue("status") === "hold"),
        },
        {
          Name: "hold",
          onclick: () => {
            store.setprops("hold_note", { hidden: false, readonly: false });
            store.setprops("Holdpopup", { hidden: false });
          },
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },
        {
          Name: "completed",
          onclick: () => {
            store.setprops("complete_note", {
              hidden: false,
              readonly: false,
            });
            store.setprops("completePopup", { hidden: false });
          },
          type: "primary",
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },
        {
          Name: "Open Editor",
          onclick: () => {
            store.navtotab(
              "/" +
                URLPRIFIX +
                "/editor/" +
                (store.getvalue("editor_id") || "1")
            );
          },
          type: "primary",
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },
      ]}
    >
      {store.getprops("Holdpopup") &&
        store.getprops("Holdpopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="hold_note"
                label="Hold Reason :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={hold}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("hold_note", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("Holdpopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      {store.getprops("completePopup") &&
        store.getprops("completePopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="complete_note"
                label="Completion Notes :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={complete}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("complete_note", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("completePopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      <div className="grid grid-cols-2 gap-10">
        <String name="subtask_title" label="Task Title :" />
        <Dropdown
          name="contact_id"
          label="Client Name :"
          list={store.getprops("clientName_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="project_id"
          label="Project Name :"
          list={store.getprops("projectName_list") || []}
          onchange={projectNameChange}
        />
        <Dropdown
          name="form_id"
          label="Form Name :"
          list={store.getprops("name_of_Artist_list") || []}
          onchange={FormNameChange}
        />
        <Dropdown
          name="task_id"
          label="Task Name :"
          list={store.getprops("task_title_list") || []}
          onchange={TaskNameChange}
        />
        <Dropdown
          name="assigned_to"
          label="Assigned To :"
          list={store.getprops("userName_list") || []}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "inprogress" },
            { value: "hold", label: "Hold" },
            { value: "completed", label: "Completed" },
          ]}
        />
        <DatePicker name="dueDate" label="Due Date :" />
        <div className="col-span-2">
          <Journal
            name="description"
            label="Document Description :"
            doubleline={true}
          />
        </div>

        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <div className="col-span-2 px-8">
          <div className="font-bold text-[32px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl") &&
              store.getvalue("attachmenturl").map((item) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                  </div>
                );
              })}
            <FileInput
              prefix={"TASK" + id}
              onchange={(file) => {
                let attachmenturl = store.getvalue("attachmenturl") || [];
                attachmenturl.push(file);
                store.setvalue("attachmenturl", attachmenturl);
              }}
            />
          </div>
        </div>
        <Journal name="hold_note" label="Hold Reason :" doubleline={true} />
        <Journal
          name="complete_note"
          label="Completion Notes :"
          doubleline={true}
        />
      </div>
    </FormView>
  );
};

export default TasksForm;
