import Dropdown from "../../Components/Fields/Dropdown";
import {
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  UpdateRecord,
  URLPRIFIX,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";

const ContactForm = () => {
  const { option, id } = useParams();
  const tablename = "contact";
  const setrecord = "/contact/addcontacts";
  const getrecord = `/contact/contacts/${id}`;
  const updaterecord = "/contact/updatecontacts/" + id;

  const store = useStore();
  const init = {
    clientName: "",
    phoneno: "",
    email: "",
    category_ID: "",
    url: "",
    activeStatus: true,
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      store.setprops("url", { hidden: false });
      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.clientName = res.record.parent[0].clientName;
          init.phoneno = res.record.parent[0].phoneno;
          init.email = res.record.parent[0].email;
          init.category_ID = res.record.parent[0].category_ID;
          init.activeStatus = res.record.parent[0].activeStatus;
          init.url = res.record.parent[0].url;
          // init.URL =
          //   window.location.origin +"/"+URLPRIFIX+
          //   "/forms/" +
          //   res.record.parent[0].id +
          //   "/" +
          //   res.record.parent[0].categoryid +
          //   "/2367481jjdc984j89duj29j";
          if (option === "view") {
            init.child = [
              {
                list: res.record.child,
                header: [
                  "film",
                  "name_of_Artist",
                  "father_Name",
                  "email",
                  "ACTION",
                ],
                table: "form",
              },
            ];
          }
          store.reset(init);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("url", { hidden: true });
    }
    GetListDropdown({
      listUrl: "/clientcategory/getClientCategorylist",
      field: "categoryName",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, []);

  function update() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    data.url = window.location.origin + "/" + URLPRIFIX + "/form";

    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }
  function Submit() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    data.url = window.location.origin + "/" + URLPRIFIX + "/form";
    saveRecord(setrecord, data).then((res) => {
      console.log("Resonce of saveRecord - ", res);
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }
  function sentMail() {
    window.open(`mailto:${store.getvalue(
      "email"
    )}?subject=Request for Form Submission to Continue Your Process&body=Hi ${store.getvalue(
      "clientName"
    )}	
    %0a
    %0aTo proceed with the next steps, please complete the form at the link provided below:
    %0a
    %0aLink : ${store.getvalue("URL")}
    %0a
    %0aPlease Note: This is an automated email. Kindly do not reply to this message.	
    %0a
    %0aRegards	
    %0aAbsolute Legal Law Firm`);
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        ,
        {
          Name: "Re- Send to mail",
          onclick: sentMail,
          type: "primary",
          visible: option === "view",
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-10">
        <String name="clientName" label="Client Name :" />
        <String name="phoneno" label="Phone Number :" />
        <String name="email" label="E-mail :" />
        <Dropdown
          name="category_ID"
          label="Catagory :"
          list={store.getprops("categoryName_list") || []}
        />

        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <String name="url" label="URL :" />
        <div className="col-span-2 px-8">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[32px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  onview={(index) => {
                    store.navTo("/demo/forms/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default ContactForm;
