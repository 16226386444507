import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getlocal } from "../../functions/localstorage";
import Login from "../../pages/Login";
import { URLPRIFIX } from "../../functions/api";
import Dashboard from "../../pages/Dashboard";
import { useStore } from "../StateProvider";
import UserList from "../../pages/users/UserList";
import UserForm from "../../pages/users/UserForm";
import ContactsList from "../../pages/Contacts/ContactsList";
import ContactForm from "../../pages/Contacts/ContactForm";
import NonLitigation from "../../pages/NonLitigation";
import FormsList from "../../pages/ClientForms/FormsList";
import FormsForm from "../../pages/ClientForms/FormsForm";
import CatagoryList from "../../pages/Catagory/CatagoryList";
import CatagoryForm from "../../pages/Catagory/CatagoryForm";
import ProjectList from "../../pages/Project/ProjectList";
import ProjectForm from "../../pages/Project/ProjectForm";
import DocumentsList from "../../pages/Documents/DocumentsList";
import DocumentsForm from "../../pages/Documents/DocumentsForm";
import TasksList from "../../pages/Tasks/TasksList";
import TasksForm from "../../pages/Tasks/TasksForm";
import Editor from "../../pages/Editor";
import FilmFormCreate from "../../pages/ClientForms/FilmFormCreate";
import Layout from "./Layout";
import UnAuthorized401 from "../../pages/UnAuthorized401";

const PageRouter = () => {
  const store = useStore();
  const props = getlocal("properties");
  let url = window.location.pathname;
  useEffect(() => {
    let login = getlocal("system").login;

    if (!login) {
      store.navTo("/login");
    } else {
      if (url === "/login" || url === "/") {
        store.navTo("/" + URLPRIFIX + "/dashboard");
      }
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/login"} />} />
      <Route path="/login" element={<Login />} />
      <Route
        path={"/demo/dashboard"}
        element={
          props?.dashboard?.view ? (
            <Layout>
              <Dashboard />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/nonlitigation/:catagory"}
        element={
          props?.nonlitigation?.view ? (
            <Layout>
              <NonLitigation />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/users/list"}
        element={
          props?.users?.view ? (
            <Layout>
              <UserList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/users/create"}
        element={
          props?.users?.view && props?.users?.add ? (
            <Layout>
              <UserForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/users/:option/:id"}
        element={
          props?.users?.view ? (
            <Layout>
              <UserForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/contacts/list"}
        element={
          props?.contacts?.view ? (
            <Layout>
              <ContactsList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/contacts/create"}
        element={
          props?.contacts?.view && props?.contacts?.add ? (
            <Layout>
              <ContactForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/contacts/:option/:id"}
        element={
          props?.contacts?.view ? (
            <Layout>
              <ContactForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/catagories/list"}
        element={
          props?.catagories?.view ? (
            <Layout>
              <CatagoryList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/catagories/create"}
        element={
          props?.catagories?.view && props?.catagories?.add ? (
            <Layout>
              <CatagoryForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/catagories/:option/:id"}
        element={
          props?.catagories?.view ? (
            <Layout>
              <CatagoryForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/projects/list"}
        element={
          props?.projects?.view ? (
            <Layout>
              <ProjectList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/projects/create"}
        element={
          props?.projects?.view && props?.projects?.add ? (
            <Layout>
              <ProjectForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/projects/:option/:id"}
        element={
          props?.projects?.view ? (
            <Layout>
              <ProjectForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/documents/list"}
        element={
          props?.documents?.view ? (
            <Layout>
              <DocumentsList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/documents/create"}
        element={
          props?.documents?.view && props?.documents?.add ? (
            <Layout>
              <DocumentsForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/documents/:option/:id"}
        element={
          props?.documents?.view ? (
            <Layout>
              <DocumentsForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/tasks/list"}
        element={
          props?.tasks?.view ? (
            <Layout>
              <TasksList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/tasks/create"}
        element={
          props?.tasks?.view && props?.tasks?.add ? (
            <Layout>
              <TasksForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/tasks/:option/:id"}
        element={
          props?.tasks?.view ? (
            <Layout>
              <TasksForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/forms/list"}
        element={
          props?.forms?.view ? (
            <Layout>
              <FormsList />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />
      <Route
        path={"/demo/forms/:option/:id"}
        element={
          props?.forms?.view ? (
            <Layout>
              <FormsForm />
            </Layout>
          ) : (
            <UnAuthorized401 />
          )
        }
      />

      <Route
        path={"/demo/forms/:clientId/:catagoryid/:formid"}
        element={<FilmFormCreate />}
      />
      <Route path={"/demo/editor/:id"} element={<Editor />} />
    </Routes>
  );
};

export default PageRouter;
