import { useStore } from "../StateProvider";

const Password = ({ className = "", name = "", label = "" }) => {
  const store = useStore();

  let props = store.getprops(name) || {};

  if (!props.hidden) {
    return (
      <div className="flex flex-col py-[4%] max-sm:py-[8%] max-sm:text-[18px] md:text-[14px] lg:text-[18px] xl:text-[28px] gap-4">
        <label className={className === "" ? "" : ""}>{label}</label>
        <input
          className={className === "" ? "" : className}
          id={name}
          type="password"
          value={store.getvalue(name)}
          disabled={props.readonly}
          onChange={(event) => {
            store.setvalue(name, event.target.value);
          }}
        />
      </div>
    );
  }
};

export default Password;
