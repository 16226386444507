import React, { useEffect } from "react";

const ListView = ({
  width,
  height,
  header = [],
  records = [],
  onedit,
  onview ,
  ondelete ,
  onclick,
  Tablehead = "min-w-[250px] py-3 pl-3 text-[24px]",
  Tablerecord = "py-3 pl-3 text-[18px] ",
}) => {
  return (
    <div
      className={`flex  overflow-auto`}
      style={{ height: height, width: width }}
    >
      <table className="table-auto w-full h-fit">
        <thead className="sticky top-0 bg-secondary text-left mt-10 border-b">
          <tr>
            {header.map((head, index) => {
              if (head !== "id") {
                return (
                  <th key={index} className={Tablehead}>
                    {head.toUpperCase()}
                  </th>
                );
              }
            })}
          </tr>
        </thead>

        <tbody>
          {records.map((item, index) => (
            <tr key={index} className={"border-b  "}>
              {header.map((field, fieldindex) => {
                // console.log("Listview actioon",field == "ACTION" && (ondelete!=undefined||onview!=undefined||onedit!=undefined),(ondelete!=undefined||onview!=undefined||onedit!=undefined));
                
                if (field == "ACTION" ) {
                  return (
                    <td key={fieldindex} className={Tablerecord}>
                      <div className="flex gap-7">
                        {onedit&&<img
                          src="/Assets/images/pencil.png"
                          className=" cursor-pointer"
                          onClick={() => {
                            onedit(item["id"]);
                          }}
                        />}
                        {onview&&<img
                          src="/Assets/images/eye.png"
                          className=" cursor-pointer"
                          onClick={() => {
                            onview(item["id"]);
                          }}
                        />}
                        {ondelete&&<img
                          src="/Assets/images/trash.png"
                          className=" cursor-pointer"
                          onClick={() => {
                            ondelete(item["id"]);
                          }}
                        />}
                      </div>
                    </td>
                  );
                }
                if (field != "id") {
                  return (
                    <td
                      key={fieldindex}
                      className={Tablerecord}
                      onClick={() => {
                        onclick &&  onclick(item["id"]);
                      }}
                    >
                      {item[field]}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListView;
