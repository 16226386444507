import Dropdown from "../../Components/Fields/Dropdown";
import {
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  UpdateRecord,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";

const ProjectForm = () => {
  const { option, id } = useParams();
  const tablename = "project";
  const getrecord = `/project/getproject/${id}`;
  const updaterecord = "/project/updateproject/" + id;
  const setrecord = "/project/createproject";

  const store = useStore();
  const init = {
    projectName: "",
    projectDescription: "",
    startDate: "",
    endDate: "",
    priority: "",
    status: "Draft",
    form_id: "",
    contact_id: "",
    activestatus: true,
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activestatus", { hidden: false });
      store.setprops("status", { hidden: false, readonly: true });
      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.projectName = res.record.parent.projectName;
          init.projectDescription = res.record.parent.projectDescription;
          init.startDate = res.record.parent.startDate;
          init.endDate = res.record.parent.endDate;
          init.priority = res.record.parent.priority;
          init.status = res.record.parent.status;
          init.form_id = res.record.parent.form_id;
          init.contact_id = res.record.parent.contact_id;
          init.activeStatus = res.record.parent.activeStatus;
          if (option === "view") {
            init.child = [
              {
                list: res.record.child,
                header: [
                  "task_title",
                  "description",
                  "priority",
                  "dueDate",
                  "status",
                  "ACTION",
                ],
                table: "Documents",
              },
            ];
          }
          store.reset(init);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activestatus", { hidden: true });
      store.setprops("status", { hidden: true });
    }
    GetListDropdown({
      listUrl: "/forms/getformslist",
      field: "name_of_Artist",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/contact/getcontacts",
      field: "clientName",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, []);

  function update() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }

  function Submit() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    UpdateRecord("/forms/updateformstatus/" + data.form_id).then((res) => {
      console.log("updateformstatus", res);
    });
    saveRecord(setrecord, data).then((res) => {
      console.log("Resonce of saveRecord - ", res);
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }

  function ClientNameChange(client_id) {
    GetRecords("/contact/contacts/" + client_id).then((res) => {
      // res = res?.record && res?.record[0].form;
      if (res.success) {
        res = res.record.child.map((item) => {
          return { value: item.id, label: item.name_of_Artist };
        });
        store.setprops("name_of_Artist_list", res);
      }
      console.log("ClientNameChange", res);
      // store.setvalue("contact_id", res.contact_id);
    });
  }

  function FormNameChange(form_id) {
    GetRecords("/forms/getforms/" + form_id).then((res) => {
      res = res?.record && res?.record[0].form;
      console.log("FormNameChange", res);
      store.setvalue("contact_id", res.contact_id);
    });
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Create Document",
          type: "primary",
          onclick: () => {
            let data = store.getrecord();
            console.log("On Create Project - ", data);

            store.getvaluetomodule("document", "project_id", id);
            store.getvaluetomodule("document", "form_id", data.form_id);

            store.navTo("/demo/documents/create");
          },
          visible: option === "view" && store?.state?.properties?.document.add,
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-10">
        <String name="projectName" label="Project Name :" />
        <Dropdown
          name="contact_id"
          label="Client Name :"
          list={store.getprops("clientName_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="form_id"
          label="Form Name :"
          list={store.getprops("name_of_Artist_list")}
          onchange={FormNameChange}
        />
        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "inprogress" },
            { value: "Completed", label: "Completed" },
          ]}
        />
        <Dropdown
          name="activestatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <div className="col-span-2">
          <Journal
            name="projectDescription"
            label="Project Description :"
            doubleline={true}
          />
        </div>
        <DatePicker name="startDate" label="Start Date :" />
        <DatePicker name="endDate" label="End Date :" />

        <div className="col-span-2 px-8">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[32px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  onview={(index) => {
                    store.navTo("/demo/documents/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default ProjectForm;
