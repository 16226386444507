// import { MenuHeader, MenuIcon, Menuoptions } from "@/constancs/cssconst";
import React, { useState } from "react";
import { useStore } from "../StateProvider";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

// import { useStore } from "../StateProvider";

const Sider = ({ menus, setsider }) => {
  const store = useStore();
  // the menu has some indicators to classify
  // every object has major components like
  //   1. "meta-data" - has the basic infor like icon location and this object is parent or not as Boolean
  //   2. child - which has the info like what are the child the going to have
  //   3. link - which create the object as a link button so we can easy to navigate to the link
  //   4. button - which help to perform any function which the store has

  const [sidemenu, setsidemenu] = useState(menus);

  function open(menu, open) {
    // setsidemenu(!sidemenu);

    menu = menu.replace("`", "");
    let position = "";
    menu.split("`").map((item) => (position += "['" + item + "'].child"));
    position = position.substring(0, position.length - 6);

    position =
      "sidemenu" +
      position +
      "['meta-data'].open = !sidemenu" +
      position +
      "['meta-data'].open";

    eval(position);
    // console.log("menus", sidemenu);
    setsidemenu((prev) => {
      prev = sidemenu;
      return { ...prev };
    });
  }

  return (
    <div className="flex flex-col pl-[5%] pt-[8%]">
      <Menus obj={sidemenu} open={open} parent={""} setsider={setsider} />
    </div>
  );
};

function Menus({ obj, parent, open, setsider }) {
  return Object.keys(obj).map((head, index) => {
    // console.log("new log", obj[head], obj[head]["meta-data"].icon);
    if (!obj[head].disabled) {
      return (
        <Menu
          key={index}
          heading={head}
          icon={obj[head]["meta-data"].icon}
          parent={obj[head]["meta-data"].parent}
          onClick={() => {
            open(parent + "`" + head, obj[head]["meta-data"].open);
          }}
          open={obj[head]["meta-data"].open}
          link={obj[head].link}
          button={obj[head].button}
          setsider={setsider}
        >
          {" "}
          {obj[head].child && (
            <Menus
              obj={obj[head].child}
              open={open}
              parent={parent + "`" + head}
              setsider={setsider}
            />
          )}
        </Menu>
      );
    }
  });
}

function Menu({
  heading,
  icon,
  parent,
  children,
  onClick,
  open,
  link,
  button,
  setsider,
}) {
  const store = useStore();
  return (
    <>
      <div
        className={
          parent
            ? "flex items-center justify-start mb-[2%] cursor-pointer"
            : "flex items-center justify-start my-[5%] cursor-pointer"
        }
        onClick={(e) => {
          e.preventDefault();
          if (!link && !button) {
            onClick();
          }
          if (link && !button) {
            store.reset({});
            store.navTo(link);
            setsider("max-sm:hidden");
          }
          if (button !== undefined && link === undefined) {
            switch (button.toLowerCase()) {
              case "logout":
                store.logout();
                setsider("max-sm:hidden");
                break;

              default:
                break;
            }
          }
        }}
      >
        {icon && (
          <img
            src={icon}
            className={
              parent
                ? "max-sm:w-[92px] max-sm:h-[67px] md:w-[30px] md:h-[30px] lg:w-[40px] lg:h-[40px] xl:w-[50px] xl:h-[50px]"
                : "max-sm:w-[92px] max-sm:h-[67px] md:w-[20px] md:h-[20px] lg:w-[25px] lg:h-[25px] xl:w-[30px] xl:h-[30px]"
            }
          />
        )}
        <div className="flex items-center gap-3">
          <h1
            className={
              parent
                ? "max-sm:text-[30px] md:text-[14px] lg:text-[18px] xl:text-[28px]"
                : "max-sm:text-[26px] md:text-[9px] lg:text-[12px] xl:text-[20px]"
            }
          >
            {heading}
          </h1>
          {(!link && !button) &&
            (open ? <FaChevronDown /> : <FaChevronRight />)}
        </div>
      </div>
      {parent && <hr className="w-[80%]" />}
      <div className=" md:ml-[12PX] lg:ml-[15PX] xl:ml-[20PX] border-l-[1px] pl-2 border-secondary">
        {open && children}
      </div>
    </>
  );
}

export default Sider;
