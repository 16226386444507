"use client";
import { useParams } from "react-router-dom";
import Dropdown from "../../Components/Fields/Dropdown";
import FileInput from "../../Components/Fields/FileInput";
import Journal from "../../Components/Fields/Journal";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import { saveRecord } from "../../functions/api";
import { getlocal } from "../../functions/localstorage";
import React, { useEffect } from "react";
import { PrimaryBTN } from "../../Constancs/cssconst";
// const querystring = require("querystring");

const FilmFormCreate = () => {
  const params = useParams();

  const store = useStore();

  const init = {
    activeform: 0,
    film: "",
    category_of_Service: "",
    nature_of_Service: "",
    name_of_Artist: "",
    email: "",
    phone_no: "",
    father_Name: "",
    addres_of_Artist: "",
    category_Id: "",
    client_Id: "",
    paN_Number: "",
    aadhar_Number: "",
    PAN: "",
    Aadhar: "",
    gst: "",
    GST_Certificate: "",
    payment_Deal: "",
    total_Remuneration: "",
    no_of_Shooting_Days: "",
    no_of_Months: "",
    payment_Trance: "",
    assistants_Payments: "",
    account_no: "",
    account_holder_Name: "",
    bank_Name: "",
    branch: "",
    ifsC_Code: "",
    order: [
      { Process: "Personal Details", stage: false },
      { Process: "ID Proofs", stage: false },
      { Process: "Bank Details", stage: false },
      { Process: "Payments & Shooting Days", stage: false },
      // { Process: "Contact Details", stage: false },
    ],
  };

  useEffect(() => {
    store.setmodule("clientform" + params.catagoryid, init);
    // store.reset(init)
  }, []);

  const order =
    (getlocal("records") &&
      getlocal("records")["clientform" + params.catagoryid] &&
      getlocal("records")["clientform" + params.catagoryid].order) ||
    [];
  return (
    <div className="h-screen ">
      <div className="grid grid-cols-4 h-full">
        <div className="bg-primary text-secondary col-span-1">
          <div className="flex h-[15%] px-[10%]  items-end">
            <img src="/Assets/images/Logo.png" className="h-[60%]" />
          </div>
          <div className="text-[48px] font-bold p-[10%]">
            Artise and Technician Form
          </div>
          <div className="px-[10%]">
            {order.map((item, index) => (
              <div className="grid grid-cols-4 h-fit " key={index}>
                <div className=" flex flex-col justify-center items-center">
                  <h1
                    className={
                      "text-primary rounded-full w-14 h-14 col-span-1 flex justify-center items-center text-[38px] font-bold " +
                      (item.stage ? "bg-Jasmine" : "bg-secondary")
                    }
                  >
                    {item.stage ? (
                      <img
                        className="h-14 py-2"
                        src="/Assets/images/checkicon.png"
                      />
                    ) : (
                      index + 1
                    )}
                  </h1>
                  {order.length - 1 !== index && (
                    <h1
                      className={
                        "border-2 h-full min-h-10 w-1 " +
                        (item.stage ? "border-Jasmine" : "border-secondary")
                      }
                    ></h1>
                  )}
                </div>
                <div className="col-span-3 text-[30px] font-bold pb-5">
                  {item.Process}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-secondary text-primary col-span-3">
          <div className="flex h-[15%] px-[10%]  items-end">
            <h1 className="text-[48px] font-bold">
              {order[store.getvalue("activeform")]?.Process}
            </h1>
          </div>
          {store.getvalue("activeform") === 0 ? (
            <div className=" h-[75%] px-[10%] ">
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>Film Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <String name="film" label="Flim Name" doubleline={true} />
                <Dropdown
                  name="nature_of_Service"
                  label="Nature Of Service"
                  doubleline={true}
                  list={[
                    { value: "service 1", label: "service 1" },
                    { value: "service 2", label: "service 2" },
                  ]}
                />
                <Dropdown
                  name="category_of_Service"
                  label="Catagory of Service"
                  doubleline={true}
                  list={[
                    { value: "hotel", label: "Hotel" },
                    { value: "flim", label: "Flim" },
                    { value: "rental", label: "Rental" },
                  ]}
                />
              </div>
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%] mt-[2%]">
                <h1>Artist Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <String
                  name="name_of_Artist"
                  label="Name of the Artist (As per Govt. ID)"
                  doubleline={true}
                />
                <String
                  name="father_Name"
                  label="Father's Name"
                  doubleline={true}
                />
                <String name="email" label="E-Mail" doubleline={true} />
                <String
                  name="phone_no"
                  label="Artist Contact Number"
                  doubleline={true}
                />
                <div className="col-span-2">
                  <Journal
                    name="addres_of_Artist"
                    label="Address of the Artist"
                    doubleline={true}
                  />
                </div>
              </div>
            </div>
          ) : store.getvalue("activeform") === 1 ? (
            <div className=" h-[75%] px-[10%] ">
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>PAN Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <String
                  name="paN_Number"
                  label="PAN Number"
                  doubleline={true}
                />
                <FileInput
                  name="paN_docs_url"
                  label="PAN Copy (optional)"
                  doubleline={true}
                  prefix={params.formid}
                />
              </div>
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>Aadhar Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <String
                  name="aadhar_Number"
                  label="Aadhar Number"
                  doubleline={true}
                />
                <FileInput
                  name="aadhar_docs_url"
                  label="Aadhar Copy (optional)"
                  doubleline={true}
                  prefix={params.formid}
                />
              </div>
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>GST Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <Dropdown
                  name="gst"
                  label="GST Number"
                  doubleline={true}
                  list={[
                    { value: "true", label: "Registered" },
                    { value: "false", label: "Un-Registered" },
                  ]}
                />
                <FileInput
                  name="gsT_doc_url"
                  label="GST Certificate (optional)"
                  doubleline={true}
                  prefix={params.formid}
                />
              </div>
            </div>
          ) : store.getvalue("activeform") === 2 ? (
            <div className=" h-[75%] px-[10%] ">
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>PAN Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <String
                  name="account_no"
                  label="Account Number"
                  doubleline={true}
                />
                <String
                  name="account_holder_Name"
                  label="Account Holder Name"
                  doubleline={true}
                />
                <String name="bank_Name" label="Bank Name" doubleline={true} />
                <String name="branch" label="Branch" doubleline={true} />
                <String name="ifsC_Code" label="IFSC Code" doubleline={true} />
              </div>
            </div>
          ) : store.getvalue("activeform") === 3 ? (
            <div className=" h-[75%] px-[10%] ">
              <div className="flex items-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>Shooting Details</h1>
                <div className="border-b-Bistre_Brown  border-b-[.5px] w-[70%] h-[1px]"></div>
              </div>
              <div className="grid grid-cols-2 mt-4 gap-5">
                <String
                  name="payment_Deal"
                  label="Payment Deal"
                  doubleline={true}
                />
                <String
                  name="total_Remuneration"
                  label="Total Remuneration"
                  doubleline={true}
                />
                <String
                  name="no_of_Shooting_Days"
                  label="No. of Shooting Days"
                  doubleline={true}
                />
                <String
                  name="no_of_Months"
                  label="No. of Months"
                  doubleline={true}
                />
                <String
                  name="payment_Trance"
                  label="Payment Trance"
                  doubleline={true}
                />
                <String
                  name="assistants_Payments"
                  label="Assistants Payments"
                  doubleline={true}
                />
              </div>
            </div>
          ) : store.getvalue("activeform") === 4 ? (
            <div className=" h-[75%] px-[10%] flex flex-col items-center">
              <div className="flex items-center justify-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
                <h1>Submitted Successfully</h1>
              </div>
              <div className="flex justify-center">
                <img className="h-[500px]" src="/Assets/images/success.png" />
              </div>
              <div
                className={PrimaryBTN + " cursor-pointer"}
                onClick={() => {
                  store.setvalue("activeform", 0);
                  store.reset(init)
                }}
              >
                New Form
              </div>
            </div>
          ) : (
            <div className=" h-[75%] px-[10%] "></div>
          )}
          {store.getvalue("activeform") !== 4 && (
            <div
              className={
                "flex  px-[10%] h-[10%] " +
                (store.getvalue("activeform") === 0
                  ? "justify-end"
                  : "justify-between")
              }
            >
              {store.getvalue("activeform") > 0 && (
                <button
                  className="bg-tertiary text-primary border-primary border font-bold py-3 px-10 rounded-xl h-fit"
                  onClick={() => {
                    if (store.getvalue("activeform") > 0) {
                      let orderdata = order;
                      orderdata[store.getvalue("activeform") - 1].stage = false;
                      store.setvalue("order", orderdata);
                      store.setvalue(
                        "activeform",
                        store.getvalue("activeform") - 1
                      );
                    }
                  }}
                >
                  Back
                </button>
              )}
              <button
                className={
                  "bg-primary text-secondary py-3 px-10 rounded-xl h-fit font-bold "
                }
                onClick={() => {
                  if (order.length - 1 === store.getvalue("activeform")) {
                    let data = store.getrecord(
                      "clientform" + params.catagoryid
                    );
                    console.log("Save records - ", data);

                    data = {
                      formDetails: {
                        form_id: params.formid,
                        category_id: Number(params.catagoryid),
                        contact_id: Number(params.clientId),
                        status: "Submitted By Client",
                      },
                      industrialNames: {
                        film: data.film,
                        category_of_Service: data.category_of_Service,
                        nature_of_Service: data.nature_of_Service,
                        name_of_Artist: data.name_of_Artist,
                        email: data.email,
                        phone_no: data.phone_no,
                        father_Name: data.father_Name,
                        addres_of_Artist: data.addres_of_Artist,
                      },
                      idProofDocs: {
                        paN_Number: data.paN_Number,
                        paN_docs_url: data.paN_docs_url,
                        aadhar_Number: data.aadhar_Number,
                        aadhar_docs_url: data.aadhar_docs_url,
                        gst: data.gst === "true" ? true : false,
                        gsT_doc_url: data.gsT_doc_url,
                      },
                      payment: {
                        payment_Deal: data.payment_Deal,
                        total_Remuneration: data.total_Remuneration,
                        no_of_Shooting_Days: data.no_of_Shooting_Days,
                        no_of_Months: data.no_of_Months,
                        payment_Trance: data.payment_Trance,
                        assistants_Payments: data.assistants_Payments,
                      },
                      bankDetails: {
                        account_no: data.account_no,
                        account_holder_Name: data.account_holder_Name,
                        bank_Name: data.bank_Name,
                        branch: data.branch,
                        ifsC_Code: data.ifsC_Code,
                      },
                    };
                    // store.state.records["clientform" + params.catagoryid];
                    console.log("Save records - ", data);

                    saveRecord("/forms/addformdetails", data);
                    let orderdata = order;
                    orderdata[store.getvalue("activeform")].stage = true;
                    store.setvalue("order", orderdata);
                    store.setvalue("activeform", 4);
                  } else {
                    let orderdata = order;
                    orderdata[store.getvalue("activeform")].stage = true;
                    store.setvalue("order", orderdata);

                    store.setvalue(
                      "activeform",
                      store.getvalue("activeform") + 1
                    );
                  }
                }}
              >
                {order.length - 1 === store.getvalue("activeform")
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilmFormCreate;
