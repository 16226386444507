import { useStore } from "../StateProvider";

const Dropdown = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  doubleline = false,
  list = [],
  onchange = () => {},
}) => {
  const store = useStore();
  let props = store.getprops(name)||{};

  if (!props.hidden) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[20px] w-full h-full px-3 "
            : className.field) +
          (doubleline ? "flex-col gap-1" : "flex-row items-center gap-3")
        }
      >
        <label
          className={
            className.lable === ""
              ? "w-fit  min-w-[200px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
        </label>

        <select
        id={name}
          className={
            className.input === ""
              ? "w-[350px] border rounded-lg px-2"
              : className.input
          }
          onChange={(event) => {
            store.setvalue(name, event.target.value);
            onchange(event.target.value);
          }}
          value={store.getvalue(name)}
          disabled={props.readonly}
          
        >
          <option>Please Choose</option>
          {list.map((item, index) => (
            <option key={index} className="w-full" value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    );
  }
};

export default Dropdown;
