import Dropdown from "../../Components/Fields/Dropdown";
import { GetRecords, saveRecord, UpdateRecord } from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";

const CatagoryForm = () => {
  const { option, id } = useParams();
  const tablename = "catagory";
  const setrecord = "/clientcategory/addCategory";
  const getrecord = `/clientcategory/getcategory/${id}`;
  const updaterecord = "/clientcategory/updatecategory/" + id;

  const store = useStore();
  const init = {
    categoryName: "",
    activeStatus: true,
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      GetRecords(getrecord).then((res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.categoryName = res.record.response[0].categoryName;
          init.activeStatus = res.record.response[0].activeStatus;
          store.reset(init);
        } else if (res.code === 401) {
          store.logout();
          store.navTo("/login");
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
    }
  }, []);

  function update() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.reset(init);
        store.navback();
      }
    });
  }

  function Submit() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    saveRecord(setrecord, data).then((res) => {
      console.log("Resonce of saveRecord - ", res);
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-10">
        <String name="categoryName" label="Catagory Name :" />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
      </div>
    </FormView>
  );
};

export default CatagoryForm;
