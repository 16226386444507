import Dropdown from "../../Components/Fields/Dropdown";
import { GetRecords, saveRecord, UpdateRecord } from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";

const UserForm = () => {
  const { option, id } = useParams();
  const tablename = "user";
  const setrecord = "/users/register";
  const getrecord = `/users/users/${id}`;
  const updaterecord = "/users/updateusers/" + id;

  const store = useStore();

  const modules2 = [
    {
      name: "dashboard",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "users",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "contacts",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "catagories",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "forms",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "nonlitigation",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },

    {
      name: "projects",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "documents",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "tasks",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
  ];
  // role_id list
  // 1 - Admin
  // 2 - Editor
  // 3 - Viewers
  const init = {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    activestatus: true,
    access_control: modules2,
    role_id: "",
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id !== undefined) {
      store.setheader(option.toUpperCase() + " USER");
      store.setprops("activestatus", { hidden: false });
      store.setprops("password", { hidden: true });
      if (option === "profile") {
        store.setprops("username", { hidden: false, readonly: false });
        store.setprops("email", { hidden: false, readonly: true });
        store.setprops("password", { hidden: true, readonly: true });
        store.setprops("activestatus", { hidden: true, readonly: false });
        store.setprops("access_control", { hidden: false, readonly: true });
        store.setprops("role_id", { hidden: false, readonly: true });
      } else {
        store.setprops("status", { hidden: false, readonly: false });
      }
      GetRecords(getrecord).then((res) => {
        console.log("USer Data - ", res, res.record);
        if (res.success) {
          init.firstname = res.record.firstName;
          init.lastname = res.record.lastName;
          init.username = res.record.userName;
          init.email = res.record.email;
          init.access_control =
            res.record.access_control &&
            res.record.access_control !== "" &&
            JSON.parse(res.record.access_control);
          init.role_id = res.record.role_id;
          init.activestatus = res.record.activeStatus;
          store.reset(init);
        } else if (res.code === 401) {
          store.logout();
          store.navTo("/login");
        }
      });
    } else {
      store.setheader("CREATE NEW USER");
      store.setprops("password", { hidden: false });
      store.setprops("activestatus", { hidden: true });
    }
  }, []);

  function update() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    data.access_control = JSON.stringify(data.access_control);
    console.log("Submiting data", data);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }

  function Submit() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    data.access_control = JSON.stringify(data.access_control);
    console.log("Submiting data", data);

    saveRecord(setrecord, data).then((res) => {
      console.log("Resonce of saveRecord - ", res);
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }

  const handlePermissionChange = (moduleName, permissionType, value) => {
    const updatedModules = store.getvalue("access_control").map((module) => {
      if (module.name === moduleName) {
        return {
          ...module,
          permissions: {
            ...module.permissions,
            [permissionType]: value,
          },
        };
      }
      return module;
    });

    // Now you can store the updated modules back
    // For example:
    store.setvalue("access_control", updatedModules);
  };

  function HandleRole(role) {
    if (role === "1") {
      // ADMIN
      let mod = modules2.map((module) => ({
        ...module,
        permissions: {
          ...module.permissions,
          on_off: true,
          add: true,
          view: true,
          update: true,
          delete: true,
          print: true,
        },
      }));
      store.setvalue("access_control", mod);
    } else if (role === "2") {
      //Editor
      let mod = modules2.map((module) => ({
        ...module,
        permissions: {
          ...module.permissions,
          on_off: true,
          view: true,
          update: true,
        },
      }));
      store.setvalue("access_control", mod);
    } else if (role === "3") {
      //Viewer
      let mod = modules2.map((module) => ({
        ...module,
        permissions: { ...module.permissions, on_off: true, view: true },
      }));
      store.setvalue("access_control", mod);
    }
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: (option === "edit"||option === "profile") && store.getprops("update"),
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-10">
        <String name="firstname" label="First Name :" />
        <String name="lastname" label="Last Name :" />
        <String name="username" label="User Name :" />
        <String name="email" label="E-mail :" />
        <String name="password" label="Password" />
        <Dropdown
          name="activestatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <Dropdown
          name="role_id"
          label="Role Name:"
          list={[
            { value: "1", label: "Admin" },
            { value: "2", label: "Editor" },
            { value: "3", label: "Viewer" },
          ]}
          onchange={HandleRole}
        />
      </div>
      <div className="module-access-control pt-7 px-3">
        <h2 className="font-bold text-[28px]">User Access Control</h2>
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th style={{ "text-align": "left" }} className="border px-6 py-3">
                Module
              </th>
              {/* <th className="border px-4 py-2">Dashboard</th>
              <th className="border px-4 py-2">Masters</th> */}
              <th className="border px-6 py-3"> On / Off</th>
              <th className="border px-6 py-3">Create</th>
              <th className="border px-6 py-3">View</th>
              <th className="border px-6 py-3">Edit</th>
              <th className="border px-6 py-3">Delete</th>
              <th className="border px-6 py-3">Print</th>
            </tr>
          </thead>
          <tbody>
            {store.getvalue("access_control") &&
              store.getvalue("access_control").map((module) => (
                <tr key={module.name}>
                  <td
                    style={{ "text-align": "left" }}
                    className="border px-6 py-3"
                  >
                    {module.name.toUpperCase()}
                  </td>

                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_on_off`}
                      checked={module.permissions.on_off}
                      disabled = {store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "on_off",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_add`}
                      checked={module.permissions.add}
                      disabled = {store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "add",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_view`}
                      checked={module.permissions.view}
                      disabled = {store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "view",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_update`}
                      checked={module.permissions.update}
                      disabled = {store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "update",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_delete`}
                      checked={module.permissions.delete}
                      disabled = {store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "delete",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_print`}
                      checked={module.permissions.print}
                      disabled = {store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "print",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </FormView>
  );
};

export default UserForm;
