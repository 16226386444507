import "./App.css";
import Layout from "./Components/Layout/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "./Components/StateProvider";
import PageRouter from "./Components/Layout/PageRouter";

function App() {
  return (
    <Router>
      <StoreProvider>
        <PageRouter/>
      </StoreProvider>
    </Router>
  );
}

export default App;
