import { UploadFile } from "../../functions/api";
import { useStore } from "../StateProvider";

const FileInput = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  doubleline = false,
  prefix = "",
  readonly = false,
  onchange = () => {},
}) => {
  const store = useStore();
  let props = store.getprops(name)||{};

  if (!props.hidden) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[20px] w-full h-full gap-3 px-3 "
            : className.field) +
          (doubleline ? "flex-col " : "flex-row items-center")
        }
      >
        {label!="" &&<label
          className={
            className.lable === ""
              ? "w-fit  min-w-[150px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
        </label>}
        <input
          className={
            className.input === "" ? "w-full rounded-lg " : className.input
          }
          id={name}
          type="file"
          disabled={readonly}
        title=""
          // value={store.getvalue(name+"_filename")?"C:\\fakelocation//"+store.getvalue(name+"_filename"):""}
          onChange={(event) => {
            var bodyFormData = new FormData();
            bodyFormData.append("file", event.target.files[0]);
            UploadFile(prefix, bodyFormData).then((res) => {
              if (res.success) {
                name!=="" && store.setvalue(name, res.data);
                onchange(res.data);
              }
            });
            // store.setvalue(name+"_filename", event.target.files[0].name);
            
          }}
        />
        {store.getvalue(name)?.replace(prefix+"/","")}
      </div>
    );
  }
};

export default FileInput;
