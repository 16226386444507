import Dropdown from "../../Components/Fields/Dropdown";
import { GetRecords, saveRecord, UpdateRecord } from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import Attachement from "../../Components/Fields/Attachement";

const FormsForm = () => {
  const { option, id } = useParams();
  // const setrecord = "/users/register";
  const tablename = "form";
  const getrecord = `/forms/getforms/${id}`;
  // const updaterecord = "/users/updateusers/" + id;

  const store = useStore();
  const init = {
    category_of_Service: "",
    email: "",
    father_Name: "",
    film: "",
    name_of_Artist: "",
    nature_of_Service: "",
    phone_no: "",
    assistants_Payments: "",
    no_of_Months: "",
    no_of_Shooting_Days: "",
    payment_Deal: "",
    payment_Trance: "",
    account_holder_Name: "",
    account_no: "",
    bank_Name: "",
    branch: "",
    ifsC_Code: "",
    aadhar_Doc_URL: "",
    aadhar_Number: "",
    gsT_Certificate_URL: "",
    gst: "",
    paN_Doc_URL: "",
    paN_Number: "",
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activestatus", { hidden: false });
      GetRecords(getrecord).then((res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.category_of_Service =
            res.record[0].industrialName.category_of_Service;
          init.email = res.record[0].industrialName.email;
          init.father_Name = res.record[0].industrialName.father_Name;
          init.film = res.record[0].industrialName.film;
          init.name_of_Artist = res.record[0].industrialName.name_of_Artist;
          init.nature_of_Service =
            res.record[0].industrialName.nature_of_Service;
          init.phone_no = res.record[0].industrialName.phone_no;
          init.assistants_Payments = res.record[0].payments.assistants_Payments;
          init.no_of_Months = res.record[0].payments.no_of_Months;
          init.no_of_Shooting_Days = res.record[0].payments.no_of_Shooting_Days;
          init.payment_Deal = res.record[0].payments.payment_Deal;
          init.payment_Trance = res.record[0].payments.payment_Trance;
          init.account_holder_Name =
            res.record[0].bankdetails.account_holder_Name;
          init.account_no = res.record[0].bankdetails.account_no;
          init.bank_Name = res.record[0].bankdetails.bank_Name;
          init.branch = res.record[0].bankdetails.branch;
          init.ifsC_Code = res.record[0].bankdetails.ifsC_Code;
          init.aadhar_Doc_URL = res.record[0].idproofs.aadhar_Doc_URL;
          init.aadhar_Number = res.record[0].idproofs.aadhar_Number;
          init.gsT_Certificate_URL = res.record[0].idproofs.gsT_Certificate_URL;
          init.gst =
            res.record[0].idproofs.gst == "true"
              ? "Registered"
              : "Un-Registered";
          init.paN_Doc_URL = res.record[0].idproofs.paN_Doc_URL;
          init.paN_Number = res.record[0].idproofs.paN_Number;
          store.reset(init);
        } else if (res.code === 401) {
          store.logout();
          store.navTo("/login");
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activestatus", { hidden: true });
    }
  }, []);

  // function update() {
  //   let data = store.getrecord();
  //   data.modifiedBy = store.getuser().id;
  //   data.id = Number(id);
  //   if (typeof data.activeStatus === "string") {
  //     data.activeStatus = data.activeStatus == "true" ? true : false;
  //   }
  //   UpdateRecord(updaterecord, data).then((res) => {
  //     if (res.success) {
  //       store.navback();
  //     }
  //   });
  // }

  // function Submit() {
  //   let data = store.getrecord();
  //   data.modifiedBy = store.getuser().id;
  //   data.createdBy = store.getuser().id;
  //   saveRecord(setrecord, data).then((res) => {
  //     console.log("Resonce of saveRecord - ", res);
  //     if (res.success) {
  //       store.navback();
  //     }
  //   });
  // }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Create Project",
          type: "primary",
          onclick: () => {
            let data = store.getrecord();
            console.log("On Create Project - ", data);

            store.getvaluetomodule("Project", "projectName", data.film);
            store.getvaluetomodule("Project", "form_id", id);

            store.navTo("/demo/projects/create");
          },
          visible: store?.state?.properties?.projects.add,
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-10">
        <div className="col-span-2 grid grid-cols-2 gap-10">
          <div className="font-bold text-[32px] col-span-2">
            Industrial Information
          </div>
          <String name="film" label="Flim Name :" />
          <String name="name_of_Artist" label="Artist Name :" />
          <String name="email" label="E-mail :" />
          <String name="phone_no" label="Phone Number :" />
          <String name="father_Name" label="Fathers Name :" />
          <String name="category_of_Service" label="Catagory of Service :" />
          <String name="nature_of_Service" label="Nature of Service :" />
        </div>
        <div className="col-span-2 grid grid-cols-2 gap-10">
          <div className="font-bold text-[32px] col-span-2">
            Payment Details
          </div>
          <String name="no_of_Shooting_Days" label="No. of Shooting Days :" />
          <String name="no_of_Months" label="No. of Months :" />
          <String name="assistants_Payments" label="Assistants Payments :" />
          <String name="payment_Deal" label="Payment Deal :" />
          <String name="payment_Trance" label="Payment Trance :" />
        </div>
        <div className="col-span-2 grid grid-cols-2 gap-10">
          <div className="font-bold text-[32px] col-span-2">Id Proofs</div>
          <String name="paN_Number" label="Pan Number :" />
          <Attachement
            name="paN_Doc_URL"
            label="Attachement :"
            onclick={(link) => {}}
          />
          <String name="aadhar_Number" label="Aadhar Number :" />
          <Attachement name="aadhar_Doc_URL" label="Attachement :" />
          <String name="gst" label="GST registered :" />
          <Attachement name="gsT_Certificate_URL" label="Attachement :" />
        </div>
        <div className="col-span-2 grid grid-cols-2 gap-10">
          <div className="font-bold text-[32px] col-span-2">Bank Details</div>
          <String name="account_holder_Name" label="Account Holder :" />
          <String name="bank_Name" label="Bank Name :" />
          <String name="account_no" label="Account Number :" />
          <String name="ifsC_Code" label="IFSC Code :" />
          <String name="branch" label="Branch Name :" />
        </div>
      </div>
    </FormView>
  );
};

export default FormsForm;
