import { useEffect } from "react";
import Password from "../Components/Fields/Password";
import String from "../Components/Fields/String";
import { login } from "../functions/api";
import { useStore } from "../Components/StateProvider";
// import { requestPermission } from "../functions/firebase";

const Login = () => {
  const store = useStore();
  // const value = StaticValue.loginpage;

  const init = {
    username: "",
    password: "",
    devicetoken: "",
  };

  useEffect(() => {
    // requestPermission()
    store.setmodule("Loginpage", init);
    // store.initModule("loginpage", init, "User Login");
    store.setheader("Hello, Admin");
    // requestPermission().then((res) => {
    //   if (res.status) {
    //     console.log("Token ", res);
        
    //     store.setvalue("devicetoken", res.token);
    //   }
    // });
  }, []);

  function HandleLogin() {
    login(store);
    // store.navTo("/demo/dashboard");
    // store.setsystemvalue("user", { name: "sam", id: "40" });
    // store.login();
  }

  return (
    <div className="grid h-full text-primary absolute w-full top-0 left-0 z-50 grid-cols-5 grid-rows-1 max-sm:grid-cols-1 max-sm:grid-rows-12 bg-primary ">
      <div className=" col-span-2 max-sm:col-span-1 max-sm:hidden"></div>
      <div className=" col-span-3 max-sm:col-span-1 max-sm:row-span-12 ">
        <div className=" bg-secondary flex flex-col max-sm:rounded-[10px] max-sm:mx-10 max-sm:my-20 max-sm:px-[8%] max-sm:py-[4.5%] px-[18%] py-[4.5%] max-sm:h-2/3 h-full">
          {/* <div className=" bg-secondary flex flex-col max-sm:rounded-t-[50px] max-sm:pt-12 px-[18%] py-[4.5%] h-full"> */}
          {/* logo and company branding  */}
          <div className="flex justify-center items-center max-sm:gap-4 md:gap-4 lg:gap-8 xl:gap-12">
            <img
              src="/Assets/images/Logo.png"
              alt="Logo"
              className="max-sm:w-[92px] max-sm:h-[67px] md:w-[52px] md:h-[37px] lg:w-[80px] lg:h-[60px] xl:w-[100px] xl:h-[80px]"
            />
            <h1 className="font-bold max-sm:text-[28px] md:text-[20px] lg:text-[26px] xl:text-[42px]">
              Absolute Legal Law Firm
            </h1>
          </div>
          {/* header For login page */}
          <div className="flex gap-5 items-center max-sm:mt-[20%] md:mt-[11%] lg:mt-[12%] xl:mt-[13%]">
            <h1 className="max-sm:text-[36px] md:text-[30px] lg:text-[38px] xl:text-[56px]">
              Login
            </h1>
            <div className="border-primary border-b-[.5px] w-[80%] h-[1px]"></div>
          </div>
          {/* Login Form */}
          <div className="flex flex-col">
            <String
              store={store}
              name="username"
              label="Username or Email"
              className={{
                field:
                  "flex max-sm:py-[8%] max-sm:text-[18px] md:text-[14px] lg:text-[18px] xl:text-[28px] gap-4 ",
                input: "border-b focus:border-b-2",
              }}
              doubleline={true}
            />
            <Password
              store={store}
              name="password"
              label="Password"
              className="border-b focus:border-b-2"
            />
            <div className="flex justify-center mt-[11%]">
              <button
                className="bg-tertiary rounded-sm font-semibold max-sm:rounded-lg max-sm:text-[18px] max-sm:py-[5px] max-sm:px-[35px] md:text-[12px] md:py-[5px] md:px-[35px] lg:text-[16px] lg:py-[6px] lg:px-[45px] xl:text-[24px] xl:py-[8px] xl:px-[65px]"
                onClick={(e) => {
                  e.preventDefault();
                  HandleLogin();
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
