import Dropdown from "../../Components/Fields/Dropdown";
import {
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  UpdateRecord,
  URLPRIFIX,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";
import FileInput from "../../Components/Fields/FileInput";
import Attachement from "../../Components/Fields/Attachement";

const DocumentsForm = () => {
  const { option, id } = useParams();
  const tablename = "document";
  const setrecord = "/tasks/createtask";
  const getrecord = `/tasks/gettask/${id}`;
  const updaterecord = "/tasks/updatetask/" + id;

  const store = useStore();
  const init = {
    project_id: "",
    form_id: "",
    contact_id: "",
    assigned_to: "",
    task_title: "",
    dueDate: "",
    priority: "",
    editor_id: "",
    status: "Draft",
    description: "",
    attachmenturl: [],
    activeStatus: true,
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      
      if (option === "view") {
        store.setprops("status", { hidden: false, readonly: true });
      } else {
        store.setprops("status", { hidden: false, readonly: false });
      }

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.project_id = res.record.parent[0].project_id;
          init.form_id = res.record.parent[0].form_id;
          init.contact_id = res.record.parent[0].contact_id;
          init.assigned_to = res.record.parent[0].assigned_to;
          init.task_title = res.record.parent[0].task_title;
          init.dueDate = res.record.parent[0].dueDate;
          init.priority = res.record.parent[0].priority;
          init.status = res.record.parent[0].status;
          init.description = res.record.parent[0].description;
          init.editor_id = res.record.parent[0].editor_id;
          init.attachmenturl = res.record.parent[0].attachmenturl || [];
          // init.activeStatus = res.record.parent[0].activeStatus;
          if (option === "view") {
            init.child = [
              {
                list: res.record.child,
                header: [
                  "subtask_title",
                  "description",
                  "priority",
                  "ACTION",
                  "dueDate",
                  "status",
                ],
                table: "Task",
              },
            ];
          }
          store.reset(init);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
    }
    GetListDropdown({
      listUrl: "/project/getprojectlist",
      field: "projectName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/forms/getformslist",
      field: "name_of_Artist",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/contact/getcontacts",
      field: "clientName",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, []);

  function update() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.navback();
        store.reset(init);
      }
    });
  }

  function Submit() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;

    saveRecord("/editor/addeditor", {
      editor_content: "Create Your Doc Hear",
    }).then((res) => {
      console.log("addeditor", res);
      if (res.success) {
        data.editor_id = res.Id.response.id;
      }
      saveRecord(setrecord, data).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.navback();
          store.reset(init);
        }
      });

      // Change the Project states into inprogress
    });

    // saveRecord(setrecord, data).then((res) => {
    //   console.log("Resonce of saveRecord - ", res);
    //   if (res.success) {
    //     store.navback();
    //     store.reset(init);
    //   }
    // });
  }
  function createTask() {
    let data = store.getrecord();
    console.log("On Create Project - ", data);

    store.getvaluetomodule("task", "task_id", id);
    store.getvaluetomodule("task", "project_id", data.project_id);
    store.getvaluetomodule("task", "form_id", data.form_id);
    store.getvaluetomodule("task", "contact_id", data.contact_id);

    store.navTo("/demo/tasks/create");
  }

  function ClientNameChange(client_id) {
    GetRecords("/contact/contacts/" + client_id).then((res) => {
      // res = res?.record && res?.record[0].form;
      if (res.success) {
        res = res.record.child.map((item) => {
          return { value: item.id, label: item.name_of_Artist };
        });
        store.setprops("name_of_Artist_list", res);
      }
      console.log("ClientNameChange", res);
      // store.setvalue("contact_id", res.contact_id);
    });
  }

  function FormNameChange(form_id) {
    GetRecords("/forms/getforms/" + form_id).then((res) => {
      res = res?.record && res?.record[0].form;
      console.log("FormNameChange", res);
      store.setvalue("contact_id", res?.contact_id);
    });
  }
  function projectNameChange(project_id) {
    GetRecords("/project/getproject/" + project_id).then((res) => {
      res = res?.record && res?.record.parent;
      console.log("projectNameChange", res);
      store.setvalue("contact_id", res?.contact_id);
      store.setvalue("form_id", res?.form_id||"");
      FormNameChange(res?.form_id)
      ClientNameChange(res?.contact_id)
    });
  }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Create Task",
          type: "primary",
          onclick: createTask,
          visible: option === "view" && store?.state?.properties?.tasks?.add,
        },
        {
          Name: "Open Editor",
          onclick: () => {
            store.navtotab(
              "/" +
                URLPRIFIX +
                "/editor/" +
                (store.getvalue("editor_id") || "1")
            );
          },
          type: "primary",
          visible:
            option === "view" && store.getvalue("status") !== "completed",
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-10">
        <String name="task_title" label="Task Title :" />
        <Dropdown
          name="contact_id"
          label="Client Name :"
          list={store.getprops("clientName_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="form_id"
          label="Form Name :"
          list={store.getprops("name_of_Artist_list") || []}
          onchange={FormNameChange}
        />
        <Dropdown
          name="project_id"
          label="Project Name :"
          list={store.getprops("projectName_list") || []}
          onchange={projectNameChange}
        />

        <Dropdown
          name="assigned_to"
          label="Assigned To :"
          list={store.getprops("userName_list") || []}
        />
        <div className="col-span-2">
          <Journal
            name="description"
            label="Document Description :"
            doubleline={true}
          />
        </div>
        <DatePicker name="dueDate" label="Due Date :" />
        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "inprogress" },
            { value: "completed", label: "Completed" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />

        <div className="col-span-2 px-8">
          <div className="font-bold text-[32px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl") &&
              store.getvalue("attachmenturl").map((item) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                  </div>
                );
              })}
            <FileInput
              prefix={"TASK" + id}
              onchange={(file) => {
                let attachmenturl = store.getvalue("attachmenturl") || [];
                attachmenturl.push(file);
                store.setvalue("attachmenturl", attachmenturl);
              }}
            />
          </div>
        </div>
        <div className="col-span-2 px-8">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[32px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  onview={(index) => {
                    store.navTo("/demo/tasks/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default DocumentsForm;
