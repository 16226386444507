import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { URLPRIFIX } from "../../functions/api";

const Header = ({ store, setsider }) => {
  return (
    <div className="grid grid-cols-10 h-full ">
      <div className=" col-span-2 ">
        <div
          className="flex justify-center items-center h-full px-[6%] gap-2 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            store.navTo("/" + URLPRIFIX + "/dashboard");
          }}
        >
          <img
            src="/Assets/images/Logo.png"
            alt="Logo"
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[42px] md:h-[30px] lg:w-[60px] lg:h-[40px] xl:w-[90px] xl:h-[65px]"
          />
          <h1 className="font-bold max-sm:text-[28px] md:text-[12px] lg:text-[16px] xl:text-[24px] max-sm:hidden">
            Absolute Legal Law Firm
          </h1>
        </div>
      </div>
      <div className=" col-span-6">
        <div className="flex items-center h-full pl-[5%]  font-bold max-sm:text-[38px] md:text-[30px] lg:text-[38px] xl:text-[58px]">
          {store.getheader().toUpperCase()}
        </div>
      </div>
      <div className=" col-span-2">
        <div className="flex items-center justify-center h-full md:gap-6 lg:gap-8 xl:gap-12 max-sm:hidden">
          {/* <img
            src="/Assets/images/notification.png"
            alt="Logo"
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px] xl:w-[60px] xl:h-[60px]"
          />
          <img
            src="/Assets/images/profile.png"
            alt="Logo"
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px] xl:w-[60px] xl:h-[60px]"
          /> */}
        </div>
        <div className="items-center justify-center h-full md:gap-6 lg:gap-8 xl:gap-12 max-sm:flex hidden">
          <div
            className="text-[38px]"
            onClick={(e) => {
              e.preventDefault();
              setsider((prev) => {
                if (prev === "max-sm:hidden") {
                  prev = "max-sm:block";
                } else {
                  prev = "max-sm:hidden";
                }
                return prev;
              });
            }}
          >
            <GiHamburgerMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
