import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import imageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "./wordEditor.css";
import htmlEditButton from "quill-html-edit-button";
import { useStore } from "../../StateProvider";
import { saveRecord, UpdateRecord } from "../../../functions/api";

const WordEditor = ({}) => {
  const store = useStore();
  // A4 sheet size 794w 1123h
  //   const [value, setValue] = useState(`

  // <h2 class="ql-align-center">
  //   <span class="ql-size-huge">
  //   </span>
  //   <strong class="ql-size-huge">
  //     NON-DISCLOSURE AGREEMENT
  //   </strong>
  // </h2>
  // <h2>
  //   &nbsp;
  // </h2>
  // <h3 class="ql-indent-1">
  //   <span class="ql-size-large">
  //      This agreement is effective this date
  //   </span>
  //   <strong class="ql-size-large">
  //     <u>
  //        29-Aug-2002
  //     </u>
  //   </strong>
  //   <span class="ql-size-large">
  //      by and between
  //   </span>
  //   <strong class="ql-size-large">
  //     <u>
  //        States Solutions Pvt Ltd
  //     </u>
  //   </strong>
  //   <span class="ql-size-large">
  //      , with its registered office at
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1 ql-align-center">
  //   <strong class="ql-size-large">
  //      AND
  //   </strong>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <span class="ql-size-large">
  //   </span>
  //   <strong class="ql-size-large">
  //      M.Argun
  //   </strong>
  //   <span class="ql-size-large">
  //      with its registered office at
  //   </span>
  //   <em class="ql-size-large">
  //   </em>
  //   <strong class="ql-size-large">
  //     <em>
  //        Nukampalayam main road, chennai- 600189
  //     </em>
  //   </strong>
  //   <em class="ql-size-large">
  //   </em>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <span class="ql-size-large">
  //     &nbsp;
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <strong class="ql-size-large">
  //      1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclosing Party :
  //   </strong>
  //   <span class="ql-size-large">
  //     &nbsp;During the term of this Agreement, Employee agrees to receive from States Solutions Pvt Ltd the following information (“Confidential Information”) set forth in Section 2.&nbsp;
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <span class="ql-size-large">
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <strong class="ql-size-large">
  //      2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Confidential Information:
  //   </strong>
  //   <span class="ql-size-large">
  //     &nbsp;Confidential Information is all information that is described in this Section or that: (i) is marked as confidential at the time of disclosure; or (ii) is unmarked but treated as confidential at the time of disclosure; or (iii) is accessed or obtained by the Employee or disclosed to the Employee, whether inadvertently or not, whether by direct or indirect, oral or written communication to the Employee.&nbsp;
  //   </span>
  // </h3>
  // <p class="ql-indent-1">
  // </p>
  // <h3 class="ql-indent-1">
  //   <strong class="ql-size-large">
  //     <u>
  //        3. Description of Confidential Information
  //     </u>
  //      :
  //   </strong>
  //   <span class="ql-size-large">
  //      The Confidential Information disclosed under this Agreement is described as:
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <span class="ql-size-large">
  //      States Solutions Pvt Ltd or its customer’s trade secrets; financial information, including pricing; technical information, including research, development, procedures, algorithms, data, designs, and know-how; and business information, including operations, planning, marketing interests, products and any other information shared or you have access in the course of mutual business.
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <span class="ql-size-large">
  //     &nbsp;
  //   </span>
  // </h3>
  // <h3 class="ql-indent-1">
  //   <strong class="ql-size-large">
  //      4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Restrictions :
  //   </strong>
  //   <span class="ql-size-large">
  //      Employee shall maintain the Confidential Information in strict confidence and disclose the Confidential Information only to its employees, subcontractors and consultants that have a need to know such Confidential Information and solely for the purpose described in Section 4., provided, that Employee shall first have entered into a confidentiality agreement with such employees, subcontractors and consultants requiring such individuals to comply with this Agreement.&nbsp;The Employee shall not disclose the Confidential Information to any third parties nor allow third parties access to the Confidential Information. The Employee shall not use the Confidential Information for any internal business use, except as mentioned in this Agreement, to the detriment of States Solutions Pvt Ltd.
  //   </span>
  // </h3>
  //   `);
  Quill.register("modules/imageResize", imageResize);
  Quill.register("modules/htmlEditButton", htmlEditButton);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video", "formula"],
      [{ color: [] }, { background: [] }],
      ["clean"],
      ["code-block"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize"],
    },
    htmlEditButton: {
      debug: true, // logging, default:false
      msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
      okText: "Ok", // Text to display in the OK button, default: Ok,
      cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
      buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
      buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
      syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
      prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
      editorModules: {}, // The default mod
    },
  };

  const formats = [
    "header",
    "size",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "formula",
    "align",
    "background",
    "color",
    "code-block",
  ];

  return (
    <div className="h-screen text-black flex justify-center text-[24px] overflow-x-auto relative">
      <ReactQuill
        className="w-[1029px] h-[90%]"
        theme="snow"
        value={store.getvalue("editor")}
        onChange={(e) => {
          // setValue(e);
          store.setvalue("editor", e);
        }}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default WordEditor;
