"use client";

import { IoChevronForwardOutline } from "react-icons/io5";
import React, { useEffect } from "react";
import {
  DateCalendar,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ListView from "../Components/Elements/ListView";
import { getList, URLPRIFIX } from "../functions/api";
import { useStore } from "../Components/StateProvider";
import { getlocal } from "../functions/localstorage";
// import { Calendar } from "@nextui-org/calendar";
// import { today, getLocalTimeZone, isWeekend } from "@internationalized/date";
// import { useLocale } from "@react-aria/i18n";

const Dashboard = () => {
  const store = useStore();
  const props = getlocal("properties");
  const init = {
    assignTask: [],
    DocumentList: [],
  };

  useEffect(() => {
    // store.setheader("Hello, " + store.getuser()?.name);
    store.setmodule("dashboard", init);
    store.setheader("Hello, " + getlocal("system")?.user?.name);
    console.log("store.getuser()?.id", getlocal("system")?.user?.id);

    loadDocument();
    loadduedatelist();
    loadtask();
    loadlogs();
  }, []);

  function loadduedatelist() {
    getList({
      listUrl: "/tasks/getprojectstasklist",
      field: store.getuser()?.role == "1" ? "duedate" : "assigned_to_username",
      search: store.getuser()?.role == "1" ? "" : store.getuser()?.id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getList catagaory", res);
      init.Duedatelistdoc = res?.data?.list;
      store.reset(init);
    });
  }
  function loadDocument() {
    getList({
      listUrl: "/tasks/getprojectstasklist",
      field: store.getuser()?.role == "1" ? "status" : "assigned_to",
      search: store.getuser()?.role == "1" ? "Inprogress" : store.getuser()?.id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getList catagaory", res);
      store.setvalue("DocumentList", res?.data?.list);
    });
  }
  function loadtask() {
    getList({
      listUrl: "/subtask/getsubtasklist",
      field: store.getuser()?.role == "1" ? "status" : "assigned_to",
      search: store.getuser()?.role == "1" ? "draft" : store.getuser()?.id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getLoadtask catagaory", res);
      store.setvalue("assignTask", res?.data?.list);
    });
  }
  function loadlogs() {
    getList({
      listUrl: "/logs/alllogs",
    }).then((res) => {
      console.log("getList catagaory", res);
      store.setvalue("Loglist", res?.data);
    });
  }

  // let now = today(getLocalTimeZone());
  // let isDateUnavailable = (date) =>
  //   isWeekend(date, locale) ||
  //   disabledRanges.some(
  //     (interval) =>
  //       date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0
  //   );
  // let disabledRanges = [
  //   [now, now.add({ days: 5 })],
  //   [now.add({ days: 14 }), now.add({ days: 16 })],
  //   [now.add({ days: 23 }), now.add({ days: 24 })],
  // ];

  // let { locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="grid h-[95%] max-sm:h-[200%] grid-cols-6 text-[#04047A]">
        <div className="bg-Alice_Blue col-span-4 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[2%] p-3 flex items-center flex-col ">
          <h1 className="font-bold text-[28px] mb-3 ">Document Updates</h1>
          <ListView
            Tablehead="bg-Alice_Blue text-[18px] font-bold"
            Tablerecord="p-2 text-[16px] cursor-pointer"
            width={"850px"}
            height={"300px"}
            onclick={(index) => {
              store.navTo("/" + URLPRIFIX + "/documents/view/" + index);
            }}
            header={[
              "task_title",
              "priority",
              "status",
              "assigned_to_username",
              "duedate",
            ]}
            records={store.getvalue("DocumentList")}
          />
        </div>
        <div className="bg-tertiary col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[2%] p-3 flex items-center flex-col gap-4 relative">
          <h1 className="font-bold text-[28px]">Task Assignments</h1>
          {props?.tasks?.add && (
            <div
              className="absolute right-5 top-4 text-[18px] bg-primary  h-9 w-9 flex justify-center items-center rounded-full  text-secondary cursor-pointer"
              onClick={() => {
                store.navTo("/" + URLPRIFIX + "/tasks/create");
              }}
            >
              +
            </div>
          )}
          <div className="h-[310px] overflow-x-auto flex items-center w-full flex-col gap-4">
            {store.getvalue("assignTask") &&
              store.getvalue("assignTask").map((item, index) => (
                <div
                  key={index}
                  className="bg-secondary rounded-full py-3 pl-5 pr-3 w-full flex items-center relative"
                  onClick={() => {
                    store.navTo("/" + URLPRIFIX + "/tasks/view/" + item.id);
                  }}
                >
                  <h1 className="text-primary font-semibold text-[22px]">
                    {item.subtask_title}
                  </h1>
                  <h1>-</h1>
                  <h1>{item.status}</h1>
                  <div className="absolute right-3 bg-secondary">
                    <IoChevronForwardOutline />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="bg-tertiary col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[2%] p-3 flex items-center flex-col gap-4 ">
          <h1 className="font-bold text-[28px]">Upcoming Due Date</h1>
          <ListView
            Tablehead="bg-tertiary text-[18px] font-bold"
            Tablerecord="p-2 text-[16px] cursor-pointer"
            width={"100%"}
            height={"300px"}
            onclick={(index) => {
              store.navTo("/" + URLPRIFIX + "/documents/view/" + index);
            }}
            header={["task_title", "duedate"]}
            records={store.getvalue("Duedatelistdoc")}
          />
          {/* <div className="h-[330px] overflow-x-auto flex items-center flex-col gap-4">
            {store.getvalue("Duedatelistdoc") &&
              store.getvalue("Duedatelistdoc").map((item, index) => (
                <div
                  key={index}
                  className="bg-secondary rounded-2xl p-3 w-full flex items-center relative"
                  onClick={() => {
                    store.navTo("/demo/tasks/view/" + item.id);
                  }}
                >
                  <h1 className="text-primary font-semibold text-[18px]">
                    {item.task_title + " - "}
                    <span className="font-light">
                      {item.dueDate.split("T")[0]}
                    </span>
                  </h1>
                  <div className="absolute right-3 bg-secondary">
                    <IoChevronForwardOutline />
                  </div>
                </div>
              ))}
          </div> */}
        </div>
        <div className="bg-[#EFF4FF]  col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[2%] relative p-3 ">
          {store.getvalue("Schedule") && store.getvalue("Schedule") ? (
            <div className="bg-[#EFF4FF] absolute w-full h-full z-50 bg-opacity-65 top-0 left-0 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] flex flex-col items-center p-3 gap-3">
              <div
                className="absolute right-5 top-4 text-[18px] bg-primary h-9 w-9 flex justify-center items-center rounded-full  text-secondary cursor-pointer"
                onClick={() => {
                  store.setvalue("Schedule", undefined);
                }}
              >
                X
              </div>
              <h1 className="font-bold text-[28px] mb-1 bg-tertiary w-full text-center md:rounded-t-[15px] lg:rounded-t-[20px] xl:rounded-t-[30px] ">
                Schedule
              </h1>
              <div className="h-[330px] overflow-x-auto flex items-center flex-col gap-4 w-full">
                {store.getvalue("Schedule").length > 0 ? (
                  store.getvalue("Schedule").map((item, index) => {
                    console.log("scghedulet item ", item);
                    return (
                      <div
                        key={index}
                        className="bg-tertiary rounded-2xl p-3 w-full flex items-center relative"
                        onClick={() => {
                          store.navTo("/demo/tasks/view/" + item.id);
                        }}
                      >
                        <h1 className="text-primary font-semibold text-[18px]">
                          {item.subtask_title + " - "}
                          <span className="font-light">
                            {item.dueDate.split("T")[0]}
                          </span>
                          {" - " + item.status}
                        </h1>
                        <div className="absolute right-3 bg-secondary">
                          <IoChevronForwardOutline />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    // key={index}
                    className="bg-secondary rounded-2xl p-3 w-full flex items-center justify-center relative h-full"
                    onClick={() => {
                      // store.navTo("/demo/task/view/" + item.id);
                    }}
                  >
                    <h1 className="text-primary font-semibold text-[28px]">
                      No-Record Found
                    </h1>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <h1 className="font-bold text-[28px] mb-3 text-center ">Schedule</h1>
          <DateCalendar
            slotProps={{
              day: {
                style: { fontWeight: "bold" },
              },
            }}
            sx={{ width: "95%", backgroundColor: "#EFF4F" }}
            className="font-bold bg-[#EFF4FF]   h-full w-[100%] rounded-xl"
            onChange={(e) => {
              getList({
                listUrl: "/subtask/getsubtasklist",
                field: "dueDate",
                search: e.format("YYYY-MM-DD"),
                sort: "asc",
                count: 100,
                store: store,
              }).then((res) => {
                console.log("Filtered Task ", res);
                store.setvalue("Schedule", res?.data?.list);
              });
            }}
          />
        </div>
        <div className="bg-Alice_Blue col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[2%] p-3 flex items-center flex-col gap-4">
          <h1 className="font-bold text-[28px]">Recent activity</h1>
          <div className="h-[330px] overflow-x-auto flex items-center w-full flex-col gap-4">
            {store.getvalue("Loglist") &&
              store.getvalue("Loglist").map((item, index) => (
                <div
                  key={index}
                  className="bg-secondary rounded-2xl p-3 w-full flex items-center relative"
                  // onClick={() => {
                  //   store.navTo("/demo/task/view/" + item.id);
                  // }}
                >
                  <h1 className="text-primary font-light text-[18px]">
                    {item.timeStamp.split("T")[0] +
                      " " +
                      item.timeStamp.split("T")[1].substring(0, 5) +
                      " - " +
                      item.operation_Type +
                      " - " +
                      item.userName +
                      " - " +
                      item.table_Name}
                  </h1>
                </div>
              ))}
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default Dashboard;
