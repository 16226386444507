import { baseurl } from "../../functions/api";
import { useStore } from "../StateProvider";
import { FaFilePdf } from "react-icons/fa6";

const Attachement = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  fileurl = "",
  doubleline = false,
  readonly = false,
  onclick = () => {},
}) => {
  const store = useStore();
  let props = store.getprops(name) || {};

  if (!props.hidden && store.getvalue(name) != undefined) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[20px] w-full h-full px-3 "
            : className.field) +
          (doubleline ? "flex-col gap-1" : "flex-row items-center gap-3")
        }
      >
        {/* <label
          className={
            className.lable === ""
              ? "w-fit  min-w-[200px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
        </label> */}

        {fileurl === "" ? (
          <button
            className={
              className.input === ""
                ? "w-full  rounded-lg font-medium flex justify-start items-center gap-4 px-4 py-2 border-b hover:bg-Alice_Blue "
                : className.input
            }
            id={name}
            disabled={readonly}
            onClick={() => {
              onclick(store.getvalue(name));
              store.setvalue("viewAttachement", store.getvalue(name));
              store.setvalue("viewAttachementname", name);
            }}
          >
            <FaFilePdf />
            {store.getvalue(name).split("/")[1]}
          </button>
        ) : (
          <button
            className={
              className.input === ""
                ? "w-full  rounded-lg font-medium flex justify-start items-center gap-4 px-4 py-2 border-b hover:bg-Alice_Blue "
                : className.input
            }
            id={name}
            disabled={readonly}
            onClick={() => {
              onclick(fileurl);
              store.setvalue("viewAttachement", fileurl);
              store.setvalue("viewAttachementname", name);
            }}
          >
            <FaFilePdf />
            {fileurl.split("/")[1]}
          </button>
        )}
        {store.getvalue("viewAttachement") !== undefined &&
          store.getvalue("viewAttachementname") === name &&
          store.getvalue("viewAttachement") !== "" && (
            <div className="absolute top-0 left-0 h-full w-full z-50">
              <button
                onClick={() => {
                  store.setvalue("viewAttachement", "");
                }}
                className="text-[32px] px-6 py-3 rounded-full absolute right-5 top-10 bg-Alice_Blue "
              >
                X
              </button>
              {store.getvalue("viewAttachement").includes("pdf") ||
              store.getvalue("viewAttachement").includes("docx") ? (
                <iframe
                  className="h-[98%] w-full bg-tertiary"
                  src={
                    baseurl +
                    "/files/preview?key=" +
                    store.getvalue("viewAttachement")
                  }
                />
              ) : (
                <img
                  className=" h-[98%] bg-tertiary"
                  s
                  src={
                    baseurl +
                    "/files/preview?key=" +
                    store.getvalue("viewAttachement")
                  }
                />
              )}
            </div>
          )}
      </div>
    );
  }
};

export default Attachement;
