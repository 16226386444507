"use client";
import Filters from "../../Components/Fields/Filters";
import Pagination from "../../Components/Fields/Pagination";
import { DeleteRecord, GetListview, URLPRIFIX } from "../../functions/api";
import React, { useEffect } from "react";
import { useStore } from "../../Components/StateProvider";
import ListView from "../../Components/Elements/ListView";

const CatagoryList = () => {
  const tablename = "catagories";
  const tableURL = "/clientcategory/getClientCategorylist";
  const DeleteURL = "/users/deleteusers/";

  const store = useStore();
  const init = {
    filterfield:"all",
    search: "",
    sort: "asc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule(tablename, init);
    store.setheader(tablename + " List");
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    });
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    });
  }

  return (
    <div className="grid grid-rows-10 h-full">
      <div className="row-span-1 relative">
        <Filters fields={store.getvalue("header")} onchange={change} />
        {store.getprops("add") && (
          <div className="absolute right-10 top-0">
            <button
              className="bg-primary text-secondary py-3 px-10 rounded-3xl"
              onClick={() => store.navTo("/demo/" + tablename + "/create")}
            >
              Add new
            </button>
          </div>
        )}
      </div>
      <div className="row-span-9 h-full w-full">
        <div className="py-5 px-[90px]" contentEditable={false}>
          <ListView
            width={"1300px"}
            height={"600px"}
            header={store.getvalue("header")}
            records={store.getvalue("list")}
            onedit={
              store.getprops("update")
                ? (index) => {
                    store.navTo(`/${URLPRIFIX}/${tablename}/edit/${index}`);
                  }
                : undefined
            }
            onview={
              store.getprops("view")
                ? (index) => {
                    store.navTo(`/${URLPRIFIX}/${tablename}/view/${index}`);
                  }
                : undefined
            }
            // ondelete={(index) => {
            //   DeleteRecord(DeleteURL + index, {
            //     modifiedBy: store.getuser().id,
            //   }).then((res) => {
            //     if (res.success) {
            //       GetListview({
            //         listUrl: tableURL,
            //         store: store,
            //         field: store.getvalue("filterfield"),
            //         search: store.getvalue("search"),
            //         sort: store.getvalue("sort"),
            //         page: store.getvalue("currentpage"),
            //       });
            //     }
            //   });
            // }}
          />
          <div className="float-right mr-8 mt-6">
            <Pagination onchange={onpage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatagoryList;
