import React, { useEffect } from "react";
import WordEditor from "../Components/Elements/WordEditor/WordEditor";
import { GetRecords, UpdateRecord } from "../functions/api";
import { useStore } from "../Components/StateProvider";
import { useParams } from "react-router-dom";

const Editor = () => {
  const { id } = useParams();

  const store = useStore();

  useEffect(() => {
    store.setmodule("Editor", { editor: "" });
    store.setheader("Editor");
    GetRecords("/editor/geteditorlist/" + id).then((res) => {
      console.log("Editor responce", res);

      if (res.success) {
        store.setvalue("editor", res.record.response[0].editor_content);
      }
    });
  }, []);

  return (
    <div className="bg-secondary">
      <WordEditor />
      <div className="flex justify-end items-center px-[2%] py-[2%] gap-5 absolute bottom-0 right-0">
        <button
          className="py-3 px-10 rounded-xl font-bold bg-tertiary text-primary"
          onClick={() => {
            console.log("Close start");
            window.close();
          }}
        >
          Back
        </button>
        <button
          className="py-3 px-10 rounded-xl font-bold bg-primary text-secondary"
          onClick={() => {
            console.log("save start");

            UpdateRecord("/editor/updateeditor/" + id, {
              editor_content: store.getvalue("editor"),
            }).then((res) => {
              console.log("Save Editor", res);
              alert("Saved Successfully");
            });
          }}
        >
          save
        </button>
      </div>
    </div>
  );
};

export default Editor;
