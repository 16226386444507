import React from "react";

const FormView = ({
  children,
  actions = [
    {
      Name: "",
      onclick: () => {},
      type: "",
      visible: true,
    },
  ],
}) => {
  return (
    <div className=" h-full py-10 px-5 relative">
      <div className=" h-[92%] overflow-x-auto">{children}</div>
      <div className=" h-[8%] w-full flex justify-end items-center px-[2%] pt-[2%] gap-5">
        {actions.map((action, index) => {
          if (action.visible) {
            return (
              <button
                key={index}
                className={
                  "py-3 px-10 rounded-xl font-bold" +
                  (action.type === "primary"
                    ? " bg-primary text-secondary"
                    : " bg-tertiary text-primary")
                }
                onClick={() => {
                  action.onclick();
                }}
              >
                {action.Name}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default FormView;
