import axios from "axios";
import { getlocal } from "./localstorage";

//const host = "localhost";
// const host = "192.168.1.35";
// const host = "192.168.1.34";
// export const baseurl = `http://${host}:5000`;
// export const baseurl = `http://${host}:5050`;
// export const baseurl = `http://${host}:44332`;
//export const baseurl = `http://${host}:`;

export const baseurl = `https://absolute-backend.binarywavesolutions.com`;

// const urlprifix = "V1";
export const URLPRIFIX = "demo";

export function login(store) {
  axios
    .post(`${baseurl}/users/login`, store.getrecord())
    .then((res) => {
      res = res.data;
      console.log("Res, ", res);
      let state = store.state
      state.system.login = true
      state.system.token = res.token
      state.system.user = { name: res.userName, id: res.userId ,role : res.role_id}
      // store.login();
      // store.setsystemvalue("token", res.token);
      // store.setsystemvalue("user", { name: res.userName, id: res.userId });
      if (res.access_control && res.access_control !== "") {
        let props = {};
        JSON.parse(res.access_control).map((item) => {
          if (item.name === "catagories") {
            props["catagories"] = item.permissions;
            props["catagory"] = item.permissions;
          } else {
            props[item.name] = item.permissions;
            props[item.name.substring(0, item.name.length - 1)] =
              item.permissions;
          }
        });
        state.properties= props

      }
      console.log("state for login", state);

      store.setstate(state);
      store.navTo("/demo/dashboard");
    })
    .catch((res) => {
      // console.log("REsponce", res);
      if (res.code === "ERR_NETWORK") {
        alert("network connection failed please contact the Admin");
      } else {
        res = res.response.data;
        // console.log("Res, ", res);
        if (res.errorMessage === "Username or password did not match.") {
          alert("Password Is Wrong please Check on the Password");
        } else if (res.errorMessage === "Invalid credentials") {
          alert("UserName Is Wrong please Check on the UserName");
        }
      }
    });
}
export function GetListDropdown({
  listUrl = "",
  field = "",
  search = "",
  sort = "",
  page = 1,
  count = 10,
  store,
}) {
  let token = getlocal("system").token;
  // console.log("token", listUrl, field, search, sort, page, count, store);
  let url = "";
  if (field !== "") url += "&fieldname=" + field;
  if (search !== "") url += "&search=" + search;
  if (sort !== "") url += "&sort=" + sort;
  if (page !== "") url += "&page=" + page;
  if (count !== "") url += "&pagesize=" + count;
  url = url.substring(1, url.length);
  url = `${baseurl}${listUrl}?${url}`;

  console.log("GetListDropdown",url);
  
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      res = res.data.list;
      // // console.log("GetListDropdown list", res);
      res = res.map((item) => {
        return { value: item.id, label: item[field] };
      });
      console.log("GetListDropdown list" + listUrl + " - ", res);

      store.setprops(field + "_list", res);
    })
    .catch((res) => {
      // console.log("Error REsponce", url, res);
      if (res.code === "ERR_NETWORK") {
        store.logout();
      }
    });
}
export function getList({
  listUrl = "",
  field = "",
  search = "",
  sort = "",
  page = 1,
  count = 10,
}) {
  let token = getlocal("system").token;
  console.log("token", listUrl, field, search, sort, page, count);
  let url = "";
  if (field !== "") url += "&fieldname=" + field;
  if (search !== "") url += "&search=" + search;
  if (sort !== "") url += "&sort=" + sort;
  if (page !== "") url += "&page=" + page;
  if (count !== "") url += "&count=" + count;
  url = url.substring(1, url.length);
  url = `${baseurl}${listUrl}?${url}`;

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log("GetList responce - ", res);
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        return { success: true, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("Error REsponce", res);
      return { success: true, errormessage: res };
    });
}
export function GetListview({
  listUrl = "",
  field = "",
  search = "",
  sort = "",
  page = 1,
  count = 10,
  store,
}) {
  let token = getlocal("system").token;
  // console.log("token", listUrl, field, search, sort, page, count, store);
  let url = "";
  if (field !== "") url += "&fieldname=" + field;
  if (search !== "") url += "&search=" + search;
  if (sort !== "") url += "&sort=" + sort;
  if (page !== "") url += "&page=" + page;
  if (count !== "") url += "&count=" + count;
  url = url.substring(1, url.length);
  url = `${baseurl}${listUrl}?${url}`;

  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log("GetListview responce - ", res);
      res = res.data;

      let totalCount = res.totalCount / count;

      // console.log("totalCount value", totalCount % 1 == 0);
      if (totalCount % 1 == 0) {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount);
      } else {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount) + 1;
      }

      store.setvalue("totalpage", totalCount);
      store.setvalue("currentpage", page);
      // // console.log("Res, ", totalCount);

      store.setvalue("list", res.list);
      let head = Object.keys(res.list[0]);
      head.splice(5, 0, "ACTION");
      store.setvalue("header", head);
    })
    .catch((res) => {
      console.log("Error REsponce", res);
      // if (res.code === "ERR_BAD_REQUEST") {
      //   store.logout();
      // }
    });
}
export function GetRecords(url) {
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = `${baseurl}${url}`;
  return axios
    .get(url, {
      headers: headers,
    })
    .then((res) => {
      console.log(" Get record Responsce - ", res);
      if (res.status === 200) {
        return { success: true, record: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return {
        success: false,
        errormessage: res,
        code: res.code === "ERR_BAD_REQUEST" ? 404 : 401,
      };
    });
}
export function DeleteRecord(url, record) {
  // console.log("Delete record - ", getlocal("system").token, url, record);
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = baseurl + url;

  return axios
    .delete(url, {
      headers: headers,
      data: record,
    })
    .then((res) => {
      // console.log(" Delete record Responsce - ", res.data.id);
      if (res.status === 200) {
        return { success: true, Id: res.data.id };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function UpdateRecord(url, record, header) {
  // console.log("Update record - ", getlocal("system").token, url, record);
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = baseurl + url;
  if (header) {
    headers = { "Content-Type": "multipart/form-data" };
  }
  console.log("UpdateRecord", url, record, header);
  return axios
    .put(url, record, {
      headers: headers,
    })
    .then((res) => {
      console.log(" Update record Responsce - ", res.data);
      if (res.status === 200) {
        return { success: true, Id: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      console.log("catch responce - ", res);
      return { success: false, errormessage: res.response };
    });
}
export function saveRecord(url, record, header) {
  // console.log("Save record - ", url, record);
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = baseurl + url;
  if (header) {
    headers = { "Content-Type": "multipart/form-data" };
  }
  return axios
    .post(url, record, {
      headers: headers,
    })
    .then((res) => {
      console.log(" Save record Responsce - ", res);
      if (res.status === 200) {
        return { success: true, Id: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function UploadFile(Prefix, file) {
  // console.log("Save record - ", Prefix, file);

  // let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  let url = baseurl + "/files/UploadFiles?prefix=" + Prefix;
  // if (header) {
  //   headers = { "Content-Type": "multipart/form-data" };
  // }
  return axios
    .post(url, file, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      // console.log(" File Save Responsce - ", res);
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function GetLitigation(key) {
  if (JSON.parse(localStorage.getItem("Nonlitigation"))) {
    return JSON.parse(localStorage.getItem("Nonlitigation"))[key];
  } else {
    SetLitigation({
      clients: [],
      catagories: [
        { label: "Hotel", value: "1" },
        { label: "Film", value: "2" },
        { label: "Rental", value: "3" },
      ],
    });
  }
}
export function GetAllLitigation() {
  if (JSON.parse(localStorage.getItem("Nonlitigation"))) {
    return JSON.parse(localStorage.getItem("Nonlitigation"));
  } else {
    SetLitigation({
      clients: [],
      catagories: [
        { label: "Hotel", value: "1" },
        { label: "Film", value: "2" },
        { label: "Rental", value: "3" },
      ],
    });
  }
}
export function SetLitigation(state) {
  localStorage.setItem("Nonlitigation", JSON.stringify(state));
}
export function Setlists(field, data) {
  let local = JSON.parse(localStorage.getItem("Nonlitigation"));
  // console.log("Local", local, field, data);
  localStorage.setItem(
    "Nonlitigation",
    JSON.stringify({ ...local, [field]: data })
  );
}
